<template>
  <div>
    <script type="module" onload="window.dwPromiseResolver()" :src="digitalWalletScript"></script>
    <error-message v-if="errorMessage" :error-message="errorMessage" :error-code="errorCode"></error-message>
    <div v-else :key="componentKey">
      <div v-if="$root.loading" class="v-overlay theme--dark">
        <div class="loader"></div>
      </div>
      <modal v-if="showModal" @close="closeModal()" @print="onPrint()" :success="responseHeader === 'APPROVED'">
        <h3 :class="{'error': !['APPROVED', 'SUCCESS'].includes(responseHeader)}" slot="header">{{responseHeader}}</h3>
        <div slot="body">
          <div v-if="responseHeader === 'APPROVED'">
            <div v-for="(value, key) in responseData" class="response-wrapper">
              <div class="response-label">{{key}}</div>
              <div class="response-value">{{value}}</div>
            </div>
          </div>
          <div v-else>
            <div>{{responseData}}</div>
          </div>
        </div>
      </modal>
      <validation-observer
          ref="observer"
          v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)" :disabled="$root.loading">
          <custom-component
              id="wrapper"
              :item="paymentPage"
              @reset="clearForm"
              :value="$root.formData" />
        </form>
      </validation-observer>
      <vue-recaptcha
          ref="recaptcha"
          @verify="onCaptchaVerified"
          @expired="onCaptchaExpired"
          size="invisible"
          :sitekey="recaptchaSiteKey"/>
    </div>
    <paay ref="paay" :card-number="computedCardNumber" :amount="Number(amountTotal || null)" :billingInfo="billingInfo" :shippingInfo="shippingInfo" :recurringInfo="recurringInfo" :value="generalInfo"></paay>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import { cardIsValid, dafRegexes } from '../../utils/validationConfig';
import config from '../../config';
import Modal from '../../components/modal.vue';
import Paay from './3ds/paay.vue';
import ErrorMessage from '../../components/error-message.vue';
import { vueNullify } from '../../utils';


const dwPromise = new Promise((resolve, reject) => {
  window.dwPromiseResolver = resolve
})

const htmlEl = document.querySelector('html');
export default {
  name: 'PaymentPage',
  components: { VueRecaptcha, Modal, ErrorMessage, Paay },
  data() {
    return {
      responseHeader: null,
      responseMessage: null,
      responseData: {},
      transactionRef: null,
      showModal: false,
      paymentPage: [],
      idMerchant: null,
      customStyles: null,
      logo: null,
      errorMessage: null,
      errorCode: null,
      recaptchaSiteKey: config.recaptchaSiteKey,
      totalAmount: null,
      componentKey: 0,
      digitalWalletData: {},
      digitalWalletScript: config.digitalWalletScript,
      threeDs: {}
    };
  },
  mounted: function () {
    setTimeout(() => {
      if (this.$root.surchargeControl?.compliant && this.$root.action !== 'save') {
        const surchargeMessageEl = document.getElementById('cardSurchargeMessage')
        const el = document.querySelector('.footer-wrap')
        const compliantMessage = `On credit card transactions there is a surcharge of ${this.computedSurchargeControl?.card?.value}%, which is not greater than our cost of acceptance. This surcharge is not applied on debit card transactions.`

        if (surchargeMessageEl) { // overwrite text with compliant message
          surchargeMessageEl.innerText = compliantMessage
          surchargeMessageEl.className = 'paragraph col-md-12 text-justify surcharge-message'
        } else {
          const newNode = document.createElement('div')
          newNode.className = 'surcharge-message-wrap card-field'

          const childNode = document.createElement('span')
          childNode.id = 'cardSurchargeMessage'
          childNode.className = 'paragraph col-md-12 text-justify surcharge-message test123'
          childNode.innerText = compliantMessage
          newNode.appendChild(childNode)

          el?.before(newNode)
        }
      }
      const cardSurchargeMessageEl = document.querySelector('.surcharge-message-wrap.card-field')
      const checkSurchargeMessageEl = document.querySelector('.surcharge-message-wrap.check-field')
      const hasCardSurcharge = this.computedSurcharge?.card?.fee || this.computedSurcharge?.card?.value
      const hasCheckSurcharge = this.computedSurcharge.check?.fee || this.computedSurcharge.check?.value
      if (!hasCardSurcharge && cardSurchargeMessageEl) cardSurchargeMessageEl.remove()
      if (!hasCheckSurcharge && checkSurchargeMessageEl) checkSurchargeMessageEl.remove()
    }, 100)
  },
  async created() {
    const paymentPageLoaded = this.loadPaymentPage();
    paymentPageLoaded.then(this.load3dsLibrary);
    await dwPromise;
    window.digitalWalletLibrary.loadScripts({
      readyToPay: this.readyToPay,
      onLoaded: paymentPageLoaded,
      process: data => {
        Object.assign(this.generalInfo, data.generalInfo)
        Object.assign(this.billingInfo, data.billingInfo)
        return this.submit()
      },
      validate: () => new Promise((resolve) => this.$refs.observer.validate().then(resolve)),
      config,
      getAppleSession: this.getAppleSession
    })
    await paymentPageLoaded;
    this.$root.supportedWallets = window.digitalWalletLibrary.supportedWallets;
    if (!this.digitalWalletData.applePay) delete this.$root.supportedWallets.applepay
    if (!this.digitalWalletData.googlePay) delete this.$root.supportedWallets.googlepay
  },
  watch: {
    'generalInfo.cardNumber': async function (to) {
      if (this.activeTab === 'card' && this.$root.surchargeControl?.compliant && this.$root.action !== 'save') {
        if (to) {
          if (cardIsValid(this.generalInfo?.cardNumber)) {
            await this.checkBinHandler()
          }
        } else {
          this.$root.surchargeData = {}
        }
      }
    },
  },
  computed: {
    computedCardNumber () {
      return this.$root.formData.generalInfo.cardNumber?.split(' ').join('') || ''
    },
    generalInfo() {
      return this.$root.formData.generalInfo;
    },
    customFields() {
      return this.$root.formData.customFields;
    },
    billingInfo() {
      return this.$root.formData.billingInfo;
    },
    shippingInfo() {
      return this.$root.formData.shippingInfo;
    },
    recurringInfo() {
      return this.$root.formData.recurringInfo;
    },
    amountTotal() {
      const amount = Number(this.generalInfo.subtotal) || Number(this.generalInfo.amount) || 0;
      const surchargeTotal = Number(this.surchargeTotal) || 0;
      return amount ? (amount + surchargeTotal).toFixed(2) : (0).toFixed(2);
    },
    surchargeTotal() {
      const { subtotal } = this.generalInfo;
      const surcharge = (this.$root.surchargeData?.bin_type === 'D' || this.$root.surchargeData?.bin_type === null) ? 0 : this.surchargeForTab?.value || this.surchargeForTab?.fee;
      const isPercent = this.surchargeForTab?.type === 'percent';
      const percentValue = (Number(subtotal) * (Number(surcharge) / 100)).toFixed(2)
      return isPercent ? percentValue : Number(surcharge).toFixed(2) || (0).toFixed(2);
    },
    surchargeForTab() {
      return this.computedSurcharge?.[this.$root.activeTab] || {};
    },
    computedSurchargeControl () {
      return (this.$root.surchargeControl?.required?.paymentPage || this.$root.surchargeControl?.compliant) && this.$root.surchargeControl
    },
    computedSurcharge () {
      const cardSurcharge = (this.computedSurchargeControl?.card?.value && this.computedSurchargeControl?.card) ||
          (this.$root.surcharge?.card?.fee && this.$root.surcharge?.card)
      const checkSurcharge = (this.computedSurchargeControl?.check?.value && this.computedSurchargeControl?.check) ||
          (this.$root.surcharge?.check?.fee && this.$root.surcharge?.check)
      return Object.assign({}, { card: { ...cardSurcharge }, check: { ...checkSurcharge } })
    },
    activeTab() {
      return this.$root.activeTab
    }
  },
  methods: {
    async getAppleSession (url) {
      const data = {
        idMerchant: this.idMerchant,
        url,
        domain: new URL(window.location).hostname
      }
      const response = await fetch(`${config.baseURL}/api/merchant/payment-page/apple-session/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.json();
      return responseData.data?.content?.session
    },
    readyToPay () {
      return {
        amount : Number(this.amountTotal || 0),
        ...this.digitalWalletData
      }
    },
    async checkBinHandler () {
      try {
        if (!this.$root.surchargeControl?.compliant) return
        const responseData = await this.getSurcharge({ card: this.computedCardNumber, slug: this.$root.formData.paymentPage.slug })
        this.$root.surchargeData = responseData
        this.generalInfo.surcharge = responseData.surcharge.value
        this.setAmounts()
      } catch (e) {
        this.generalInfo.surcharge = 0
        this.setAmounts()
        this.$root.surchargeData = {
          // The actual type is unknown, but we're marking it as debit to force no surcharge.
          bin_type: 'D'
        }
      }
    },
    async getSurcharge (data) {
      const response = await fetch(`${config.baseURL}/api/merchant/card-surcharge-by-slug`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.json();
      return responseData.data.content
    },
    toCurrency(amount) {
      return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    },
    expirationDisplay(exp) {
      const month = exp.substr(4, 2);
      const year = exp.substr(0, 4);
      return month + '/' + year;
    },
    async clearForm() {
      vueNullify(this.generalInfo);
      vueNullify(this.customFields);
      vueNullify(this.billingInfo);
      vueNullify(this.shippingInfo);
      this.$root.formData.recurringInfo = {};
      this.$root.surchargeData = {};
      this.transactionRef = null;
      this.componentKey += 1;
      requestAnimationFrame(() => {
          this.$refs.observer.reset();
      })
      if (window.digitalWalletLibrary) {
          await this.$nextTick();
          window.digitalWalletLibrary.reset();
      }
    },
    async load3dsLibrary () {
      const provider = this.get3DsProvider()
      if (provider) {
        return provider.init(this.threeDs)
      }
    },
    get3DsProvider () {
      if (!this.threeDs) return
      const providerName = this.threeDs.provider
      return this.$refs[providerName]
    },
    async loadPaymentPage() {
      this.errorMessage = '';
      this.errorCode = null;

      const pathParts = location.pathname.split('/');
      const slug = pathParts[1];
      if (pathParts.length > 2 || !slug.match(/^[\w\-]+$/)) {
        this.errorCode = 404;
        this.errorMessage = 'Not Found';
        return;
      }
      this.$root.formData.paymentPage.slug = slug;

      try {
        const response = await fetch(`${config.baseURL}/api/merchant/payment-page/${slug}`);
        const data = await response.json();

        if (data.status === 404) {
          this.errorCode = 404;
          this.errorMessage = 'Not Found';
          return;
        } else if (data.status === 401) {
          this.errorCode = 401;
          this.errorMessage = 'Unauthorized';
          return;
        } else if (!data.data.content) {
          this.errorCode = 500;
          this.errorMessage = 'Error encountered, please contact support.';
          return;
        }

        const paymentPageData = data.data.content.paymentPage;
        const { isoWebsite } = paymentPageData;
        window.document.title = paymentPageData.name || 'Payment Form';
        this.logo = paymentPageData.logo;
        this.paymentPage = JSON.parse(paymentPageData.json) || {};
        this.threeDs = paymentPageData.threeDs || {};
        this.$root.formData.paymentPage.isoWebsite = isoWebsite && !isoWebsite.match(/^(?:https|http):/)
            ? `https://${isoWebsite}`
            : isoWebsite;
        this.$root.surcharge = JSON.parse(paymentPageData.surcharge);
        this.$root.template = paymentPageData.template;
        this.$root.surchargeControl = paymentPageData.surchargeControl;
        this.$root.availableTabs = paymentPageData.availableTabs;
        this.$root.activeTab = this.$root.availableTabs.sort()[0]
        this.$root.availableDafs = paymentPageData.availableDafs || [];
        this.$root.action = paymentPageData.action;
        this.digitalWalletData = {
          company: paymentPageData.company,
          googlePay: paymentPageData.googlepay,
          applePay: paymentPageData.applepay
        }
        this.idMerchant = paymentPageData.idMerchant;
        this.customStyles = paymentPageData.styles;
        const styleEl = document.createElement('style');
        const head = document.head || document.getElementsByTagName('head')[0];
        head.appendChild(styleEl);
        styleEl.innerHTML = this.customStyles;
        this.updateTheme(paymentPageData.theme);
      } catch (error) {
        console.error(error);
        this.errorCode = 500;
        this.errorMessage = 'Error encountered, please contact support.';
      }
    },
    setAmounts() {
      this.generalInfo.amount = this.amountTotal;
      this.$root.totalAmount = this.generalInfo.amount;
      this.$root.formData.totalAmount = this.generalInfo.amount;
      this.generalInfo.surcharge = this.surchargeTotal;
      this.generalInfo.tax = 0 // currently don't support tax
    },
    setExpiration() {
      if (!this.generalInfo.expiryMonth && !this.generalInfo.expiryYear) return;
      this.generalInfo.expir = this.generalInfo.expiryMonth + this.generalInfo?.expiryYear?.toString()?.substring(2)
    },
    setCardNumber() {
    // because of the v-mask, card was being submitted with spaces. This allows for displaying with spaces, but submitting without.
      if (!this.generalInfo.cardNumber) return;
      this.$root.formData.generalInfo.card = this.computedCardNumber;
    },
    setCardType() {
      if (this.activeTab !== 'daf') return;
      const cardTypeMap = {
        pledger : 'Pledger',
        donors_fund : 'Donors Fund',
      }
      const card = Object.entries(dafRegexes).find(([type, regex]) => regex.test(this.generalInfo.cardNumber.replace(/\s/g,'')))
      this.$root.formData.generalInfo.cardType = cardTypeMap[card?.[0]];
    },
    setRecurringData() {
      if (!this.recurringInfo?.remTransNumber && !this.recurringInfo?.schedule) {
        this.$root.formData.recurringInfo = null;
      } else {
        this.recurringInfo.name = 'Submitted by Payment page';
        this.recurringInfo.next = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
      }
    },
    setAcceptedTerms() {
      if (!this.$root.termsAndConditions) return;
      if (!this.generalInfo.acceptedTerms) return;
      this.generalInfo.termsAndConditions = {
        acceptedTerms: true,
        text: this.$root.termsAndConditions,
      };
    },
    getMaskedData(field) {
      if (!field) return null;
      const mask = 'X'.repeat(field.length - 4);
      const last4 = field.slice(-4);
      return `${mask}${last4}`;
    },
    submit() {
      this.$root.loading = true;
      this.setExpiration();
      this.setAcceptedTerms();
      this.setCardNumber();
      this.setCardType();
      this.setRecurringData();
      // todo: save the actual T&C that the user agreed to, on the transaction.
      this.$refs.recaptcha.execute();
    },
    async onCaptchaVerified(recaptchaToken) {
      this.$root.formData.recaptchaToken = recaptchaToken;
      this.$refs.recaptcha.reset();

      const provider = this.$root.activeTab === 'card' && this.get3DsProvider()
      if (provider) {
        try {
          const response = await provider.authenticate()
          Object.assign(this.$root.formData.generalInfo, response)
        } catch (err) {
          console.error(err)
        }
        this.$root.loading = true;
      }

      const requestData = JSON.parse(JSON.stringify(this.$root.formData));
      delete requestData.generalInfo.cardNumber;

      // Convert amount fields to numbers
      requestData.generalInfo.amount = Number(requestData.generalInfo.amount) || 0;
      requestData.generalInfo.subtotal = Number(requestData.generalInfo.subtotal) || 0;
      requestData.generalInfo.surcharge = Number(requestData.generalInfo.surcharge) || 0;
      requestData.totalAmount = Number(requestData.totalAmount) || 0;

      try {
        const url = `${config.baseURL}/api/merchant/process-payment-page/${this.$root.formData.paymentPage.slug}`;
        const res = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        });

        if (res.status === 404) {
          this.responseHeader = 'Error';
          this.responseData = 'Not Found';
          return;
        }
        if (res.status === 401) {
          this.responseHeader = 'Error';
          this.errorMessage = 'Unauthorized';
          return;
        }

        const resBody = await res.json();

        if (resBody.status === 'success' && this.$root.action === 'save') {
          this.responseHeader = 'SUCCESS';
          this.responseData = 'Customer and payment method saved.';
          return;
        }

        const responseContent = resBody?.data?.content;
        if (!responseContent) {
          this.responseHeader = 'Error';
          this.responseData = `Error encountered, please contact support.${resBody.code ? ` (${resBody.code})` : ''}`;
          return;
        }

        this.transactionRef = responseContent.transactionRef;

        if (resBody.status === 'success') {
          this.responseHeader = resBody.message.toUpperCase();

          const { receiptTemplate } = responseContent;

          Object.assign(this.responseData, {
            [receiptTemplate?.authNumber?.label || 'Authorization Number']: responseContent.authId,
            [receiptTemplate?.amount?.label || 'Subtotal']: this.toCurrency(responseContent.transaction.Amount),
            [receiptTemplate?.type?.label || 'Type']: responseContent.transaction.Type,
            [receiptTemplate?.createdAt?.label || 'Date']: responseContent.createdAt,
            [receiptTemplate?.totalAmount?.label || 'Total Amount']: this.toCurrency(responseContent.totalAuthAmount || responseContent.totalAmount),
          });

          if (responseContent.card) {
            Object.assign(this.responseData, {
              [receiptTemplate?.cardNumber?.label || 'Card']: this.getMaskedData(responseContent.card),
              [receiptTemplate?.expirDate?.label || 'Expiry Date']: this.expirationDisplay(responseContent.transaction.ExpiryDate),
              [receiptTemplate?.cardType?.label || 'Card Type']: responseContent.cardType,
            });
          }

          if (responseContent.accountNumber) {
            this.responseData[receiptTemplate?.accountNum?.label || 'Account Number'] = this.getMaskedData(responseContent.accountNumber);
          }

          if (responseContent.transaction.Surcharge) {
            this.responseData[receiptTemplate?.surcharge?.label || 'Surcharge'] = this.toCurrency(responseContent.transaction.Surcharge);
          }
        } else {
          this.responseHeader = resBody.status.toUpperCase();
          this.responseData = resBody.message;
        }
      } catch (error) {
        console.error(error);
        this.responseHeader = 'Error';
        this.responseData = 'Error encountered, please contact support.';
      } finally {
        this.showModal = true;
        this.$root.loading = false;
      }
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    async onPrint() {
      try {
        const response = await fetch(`${config.baseURL}/api/merchant/print-receipt/${this.transactionRef}`, { method: 'POST' });
        const { data } = await response.json();
        const newWin = window.open('');
        newWin.document.write(data?.content?.content);
        newWin.print();
        newWin.close();
      } catch (error) {
        console.error(error);
      }
    },
    closeModal() {
      this.showModal = false;
      if (['APPROVED', 'SUCCESS'].includes(this.responseHeader)) this.clearForm();
      this.responseData = {};
      this.responseHeader = null;
    },
    updateTheme(newTheme, oldTheme) {
      if (oldTheme) htmlEl.classList.remove(oldTheme);
      htmlEl.classList.add(newTheme);
    },
  },
};
</script>
